<template>
    <v-row class="no-gutters" no-gutters>
        <!-- DESKTOP SERVICES CONTENT-->
        <v-col class="hidden-sm-and-down fill-height" cols="10">
            <v-flex style="height: auto; margin-bottom: 5%;">
                <!-- SERVICE JUMBOTRON -->
                <v-img
                    :src="require(`../../assets/services/${servicesList[selected]['image']}`)"
                    class="fill-height"
                    contain
                ></v-img>

                <!-- SERVICE TITLE -->
                <v-row align="center" class="hidden-sm-and-down mt-10 mb-6" justify="center">
                    <h1
                        :style="{'color': servicesList[selected]['color']}"
                        style="font-family: Montserrat, sans-serif; font-weight: 700; font-size: 270%"
                    >
                        {{ servicesList[selected]['formattedTitle'] }}
                    </h1>
                </v-row>

                <!-- TWO COLUMNS SERVICE DESCRIPTION SECTION -->
                <v-row
                    v-if="servicesList[selected]['text2'] !== ''"
                    class="mx-16 mb-14"
                    style="font-size: 1.5rem"
                >
                    <!-- LEFT TEXT COLUMN -->
                    <v-row align="center" justify="center">
                        <v-col :cols="$vuetify.breakpoint.smAndDown? 12:8" class="pa-8 pt-16">
                            <p class="service-description">
                                {{ servicesList[selected]['text1'] }}
                            </p>
                        </v-col>

                        <v-col cols="4" class="pa-8" align-self="center" justify="center" align="center">
                            <v-icon style="font-size: 4.5em;" dark :color="servicesList[selected]['color']">
                                {{ servicesList[selected]['icons'][0] }}
                            </v-icon>
                        </v-col>
                    </v-row>


                    <!-- RIGHT TEXT COLUMN -->
                    <v-row justify="end">
                        <v-col cols="4" class="pa-8" align-self="center" justify="center" align="center">
                            <v-icon style="font-size: 4.5em;" dark :color="servicesList[selected]['color']">
                                {{ servicesList[selected]['icons'][1] }}
                            </v-icon>
                        </v-col>

                        <v-col :cols="$vuetify.breakpoint.smAndDown? 12:8" class="pa-8 pt-16">
                            <p class="service-description">
                                {{ servicesList[selected]['text2'] }}
                            </p>
                        </v-col>
                    </v-row>

                </v-row>

                <!-- TECH LOGOS TITLE (DESKTOP) -->
                <v-row class="pt-12 mt-12" justify="center">
                    <h2 class="subtitles" style="font-weight: bold">
                        {{ servicesList[selected]['techSubtitle'].toUpperCase() }}
                    </h2>
                </v-row>

                <!-- TECH LOGOS (DESKTOP) -->
                <v-row class="pt-16 mx-16 px-16" fluid justify="center">
                    <v-col class="mx-16 px-16" align="center">
                        <!-- SQUARE LOGOS -->
                        <v-row align="center" fluid justify="center">
                            <v-col class="flex-grow-0 flex-shrink-1 mx-10" lg="2" md="2" sm="3">
                                <v-img
                                    :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][0]}`)"
                                    width="100%"
                                ></v-img>
                            </v-col>
                            <v-col class="flex-grow-0 flex-shrink-1 mx-10" lg="2" md="2" sm="3">
                                <v-img
                                    :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][1]}`)"
                                    height="100%"
                                ></v-img>
                            </v-col>
                            <v-col class="flex-grow-0 flex-shrink-1 mx-10" lg="2" md="2" sm="3">
                                <v-img
                                    :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][2]}`)"
                                    height="100%"
                                ></v-img>
                            </v-col>
                        </v-row>

                        <!-- RECTANGULAR LOGOS -->
                        <v-row v-if="servicesList[selected]['tech'][3] !== null || servicesList[selected]['tech'][4] !== null" align="center" fluid justify="center">
                            <v-col v-if="servicesList[selected]['tech'][3] !== null" class="flex-grow-0 flex-shrink-1 mr-4" lg="4" md="4" sm="4">
                                <v-img
                                    :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][3]}`)"
                                    width="100%"
                                ></v-img>
                            </v-col>

                            <v-col v-if="servicesList[selected]['tech'][4] !== null" class="flex-grow-0 flex-shrink-1 ml-4" lg="4" md="4" sm="4">
                                <v-img
                                    :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][4]}`)"
                                    width="100%"
                                ></v-img>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-row>

                <!-- CONTACT BUTTON (DESKTOP) -->
                <v-row class="pt-10 pb-16" justify="center">
                    <Button
                        :color="servicesList[selected]['color']"
                        :name="buttonInfo[0]['text']"
                        :usage-function="gotoContactScreen"
                        usage="router"
                        w="180px"
                    ></Button>
                </v-row>
            </v-flex>
        </v-col>

        <!--SERVICES MENU BUTTONS-->
        <v-col
            class="fill-height no-gutters pa-0 menu-services hidden-sm-and-down mt-4"
            style="position:fixed; right: 0; z-index: 1"
            cols="2"
        >
            <v-list class="pa-0 hidden-sm-and-down" height="100%">
                <v-list-item
                    v-for="(item, index) in servicesList"
                    :id="item['title']"
                    :key="index"
                    :class="item.class"
                    class="pa-0"
                    style="font-family: Montserrat, sans-serif; font-weight: 800"
                    @click="changeServiceSelection(index)"
                >
                    <v-flex
                        :id="'tile-' + item['title']"
                        class="fill-height tile"
                        v-html="item['title']"
                    />
                </v-list-item>
            </v-list>
        </v-col>

        <!-- MOBILE SERVICES CONTENT-->
        <v-col cols="12" class="hidden-md-and-up">
            <!-- SERVICE TITLE -->
            <div
                :style="{'color': servicesList[selected]['color']}"
                class="mobileTitle mt-8 mb-4"
                v-html="servicesList[selected]['formattedTitle']"
            ></div>

            <!-- SERVICE JUMBOTRON -->
            <v-img
                :src="require(`../../assets/services/${servicesList[selected]['image']}`)"
                width="100%"
            ></v-img>

            <!-- TWO ROWS SERVICE DESCRIPTION SECTION -->
            <v-row
                v-if="servicesList[selected]['text2'] !== ''"
                class="mx-5 mb-14"
                style="font-size: 1.3rem"
            >
                <!-- FIRST TEXT -->
                <v-col class="pa-6 pt-12" cols="12">
                    <p class="service-description-mobile">
                        {{ servicesList[selected]['text1'] }}
                    </p>
                </v-col>

                <!-- SECOND TEXT -->
                <v-col class="pa-6 pt-18" cols="12">
                    <p class="service-description-mobile">
                        {{ servicesList[selected]['text2'] }}
                    </p>
                </v-col>
            </v-row>

            <!-- TECH LOGOS TITLE (MOBILE) -->
            <v-row class="px-8 no-gutters" justify="center">
                <p class="service-description-mobile" style="font-weight: bold; text-align: center">
                    {{ servicesList[selected]['techSubtitle'].toUpperCase() }}
                </p>
            </v-row>

            <!-- TECH LOGOS (MOBILE) -->
            <v-container class="px-10" fluid justify="center">
                <v-col align="center">
                    <!-- SQUARE LOGOS -->
                    <v-row align="center" fluid justify="center">
                        <v-col class="flex-grow-0 flex-shrink-1 mx-10" lg="2" md="2" sm="2">
                            <v-img
                                class="my-8 mx-16"
                                v-if="windowWidth >= 600"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][0]}`)"
                            ></v-img>
                            <v-img
                                v-else
                                class="my-8 mx-16"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][0]}`)"
                                width="30%"
                            ></v-img>
                        </v-col>
                        <v-col class="flex-grow-0 flex-shrink-1 mx-10" lg="2" md="2" sm="2">
                            <v-img
                                class="my-8 mx-8"
                                v-if="windowWidth >= 600"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][1]}`)"
                            ></v-img>
                            <v-img
                                v-else
                                class="my-8 mx-8"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][1]}`)"
                                width="30%"
                            ></v-img>
                        </v-col>
                        <v-col class="flex-grow-0 flex-shrink-1 mx-10" lg="2" md="2" sm="2">
                            <v-img
                                class="my-8 mx-8"
                                v-if="windowWidth >= 600"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][2]}`)"
                            ></v-img>
                            <v-img
                                v-else
                                class="my-8"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][2]}`)"
                                width="30%"
                            ></v-img>
                        </v-col>
                    </v-row>

                    <!-- RECTANGULAR LOGOS -->
                    <v-row v-if="servicesList[selected]['tech'][3] !== null || servicesList[selected]['tech'][4] !== null" align="center" fluid justify="center">
                        <v-col v-if="servicesList[selected]['tech'][3] !== null" class="flex-grow-0 flex-shrink-1 mx-4" lg="3" md="3" sm="3">
                            <v-img
                                v-if="windowWidth >= 600"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][3]}`)"
                            ></v-img>
                            <v-img
                                v-else
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][3]}`)"
                                width="70%"
                            ></v-img>
                        </v-col>
                        <v-col v-if="servicesList[selected]['tech'][4] !== null" class="flex-grow-0 flex-shrink-1 mx-0" lg="3" md="3" sm="3">
                            <v-img
                                class="my-8"
                                v-if="windowWidth >= 600"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][4]}`)"
                            ></v-img>
                            <v-img
                                v-else
                                class="my-8"
                                :src="require(`../../assets/services/tech/${servicesList[selected]['tech'][4]}`)"
                                width="70%"
                            ></v-img>
                        </v-col>
                    </v-row>

                </v-col>
            </v-container>

            <!-- CONTACT BUTTON (MOBILE) -->
            <v-row class="pt-0 mb-16 pb-16 no-gutters" justify="center">
                <Button
                    :color="servicesList[selected]['color']"
                    :usage-function="gotoContactScreen"
                    name="PONTE EN CONTACTO"
                    usage="router"
                    :index="0"
                    w="195px"
                ></Button>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import * as db from "@/constants/services_database"
import Button from '@/components/buttons/Button.vue'

export default {
    name: "ServicesScreen",
    components: {Button},
    props: [
        'service'
    ],
    data() {
        return {
            servicesList: null,
            mediaTitle: null,
            selected: 0,
            buttonInfo: null,
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        },
        //THIS HELPS THE SIZE OF THE BUTTONS OF THE NAVBAR TO BE RESPONSIVE
        size() {
            const size = {xs: 'x-small', sm: 'small', lg: 'large', xl: 'x-large'}[this.$vuetify.breakpoint.name];
            return size ? {[size]: true} : {}
        },
    },
    beforeMount() {
        //Checks store value to import localized text
        if (this.language === 'es') {
            this.servicesList = db.services_info_es;
            this.mediaTitle = db.services_media_es;
            this.buttonInfo = db.button_es;
        } else {
            this.servicesList = db.services_info_en;
            this.mediaTitle = db.services_media_en;
            this.buttonInfo = db.button_en;
        }
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
        });

        if (this.$route.query['service'] !== undefined) {
            this.service = this.$route.query.service;

        }

        if (this.service !== undefined) {
            this.selected = this.service;

        } else {
            this.selected = 0;

        }

        this.changeServiceSelection(this.selected);


    },
    methods: {
        // Execute this method when user clicks on services buttons on desktop version. Template changes its content
        // whenever a new service is selected.
        changeServiceSelection(index) {
            this.selected = index;

            window.scrollTo(0, 0);

            for (let x = 0; x < this.servicesList.length; x++) {
                document.getElementById(this.servicesList[x]['title']).style.backgroundColor = '#FFF';
                document.getElementById("tile-" + this.servicesList[x]['title']).style.color = '#2a36bd';
            }

            document.getElementById(this.servicesList[index]['title']).style.backgroundColor = this.servicesList[index]['color'];
            document.getElementById("tile-" + this.servicesList[index]['title']).style.color = '#FFF';
        },

        gotoContactScreen: function () {
            this.$router.push('/contact');
        },

    }
}
</script>

<style scoped>

.v-list-item {
    height: 13.125vh;
    text-align: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.1rem;
}

.mobileTitle {
    text-align: center;
    justify-content: center;
    color: #09B408;
    font-size: 2em;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
}

.mobile {
    border-left: 0.7rem solid #09B408;
}

.mobile:hover {
    background-color: #09B408;
    color: white;
}

.web {
    border-left: 0.7rem solid #FE7700;
}

.web:hover {
    background-color: #FE7700;
}

.websites {
    border-left: 0.7rem solid #FFB902;
}

.websites:hover {
    background-color: #FFB902;
}

.ecommerce {
    border-left: 0.7rem solid #16CCCC;
}

.ecommerce:hover {
    background-color: #16CCCC;
}

.desktop {
    border-left: 0.7rem solid #FD0080;
}

.desktop:hover {
    background-color: #FD0080;
}

.marketing {
    border-left: 0.7rem solid #9961F2;
}

.marketing:hover {
    background-color: #9961F2;
}

.branding {
    border-left: 0.7rem solid #3F64D9;
}

.branding:hover {
    background-color: #3F64D9;
}

.menu-services {
    flex-direction: column;
    float: right;
}

.tile {
    color: #3F64D9;
    padding: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.tile:hover {
    color: white;
}

.quotationBtn {
    color: white;
    font-size: large;
    font-weight: 600;
    border-radius: 10px !important;
    display: inline-block
}

.quotationBtnMobile {
    color: white;
    font-weight: 600;
    border-radius: 10px !important;
}

.service-description {
    text-align: justify;
    font-size: 18px;
    font-family: Montserrat, sans-serif;
}

.subtitles {
    text-align: justify;
    font-size: 1.75em;
    font-family: Montserrat, sans-serif;
}

.service-description-mobile {
    text-align: justify;
    font-size: 15px;
    font-family: Montserrat, sans-serif;
}

@media (width: 1680px), (width: 1440px) {
    .v-list-item {
        height: 13vh;
        text-align: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1rem;
    }
}

@media (width: 1280px) {
    .v-list-item {
        height: 12.6vh;
        text-align: center;
        justify-content: center;
        font-weight: bold;
        font-size: 0.9rem;
    }
}
</style>